<template>
  <v-app style="background-color: #f7f7fa">
    <!--
    <v-navigation-drawer v-model="drawer" app temporary location="right">
      <v-list dense>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar fixed elevation="1">
      <v-toolbar-title align="left" class="mr-5 mt-1">
        <v-img src="@/assets/logo.png" width="100" height="50"/>
      </v-toolbar-title>
      <!--  <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <font-awesome-icon size="xl" :icon="['fas', 'bars']" />
      </v-app-bar-nav-icon> -->
    </v-app-bar>

    <v-main>
      <v-container class="pt-0 px-3 text-white" style="max-width: 100%">
        <v-img
            src="@/assets/banner.png"
            cover
            gradient="to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.5)"
            height="300"
            class="white--text"
            style="background-position:top !important;"
        >
          <div class="ml-4 mx-lg-16">
            <v-row align-self="center" style="height: 200px" class="mt-14" no-gutters>
              <v-col cols="12" align="center">
                <div style="width: 90%" class="headline text-white text-h5 font-weight-bold">Dobro došli u budućnost prijevoza u Zagrebu</div>
              </v-col>
              <v-col cols="12" align="center">
                <span class="headline text-white text-subtitle-1 font-weight-bold">Vaša biciklistička pretplata koja uključuje besplatne popravke i održavanje</span>
              </v-col>
            </v-row>
          </div>
        </v-img>

        <v-row class="my-3 py-5 px-4 text-black font-weight-bold">
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'person-biking']" />
              </v-col>
              <v-col  class="mt-2">
                Tvoj bicikl
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'screwdriver-wrench']" />
              </v-col>
              <v-col class="mt-2">
                Popravak uključen
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'wallet']" />
              </v-col>
              <v-col class="mt-2">
                Pristupačne cijene
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'xmark']" />
              </v-col>
              <v-col class="mt-2">
                Lako otkazivanje
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <v-col cols="12">
            <bike-card class="ma-4" title="Kvalitetni bicikli" subtitle="Ručna izrada lokalnih malih poduzeća" image="@/assets/bicikl-silver.jpg"></bike-card>
          </v-col>
        </v-row>

        <v-row class="text-black">
          <v-col cols="12" class="text-h6 font-weight-bold px-7">
            Cijene
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <v-slide-group>
              <v-slide-group-item
                  v-for="(plan, index) in prices"
                  :key="index"
              >
                <v-card
                    outlined
                    :elevation="2"
                    class="my-4 mr-4 pa-2 rounded-xl"
                    width="300"
                    :style="'background-color: #FFE5B4; border-radius: 8px; transition: transform 0.2s ease;'"
                >
                  <v-card-title class="text-h5 font-weight-bold">
                    {{ plan.price }} €
                    <span class="ml-2 grey--text">/ mjesečno</span>
                  </v-card-title>
                  <v-card-subtitle class="text-subtitle-2">
                    {{ plan.description }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col>
                        <v-chip v-if="plan.save" size="small" variant="flat" color="#2196F3">
                          Save {{plan.save}}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2" no-gutters>
                      <v-col>
                        <v-chip v-if="plan.duration === '12 mj'" size="small" variant="flat" color="#2196F3">
                          <font-awesome-icon class="mr-2" :icon="['fas', 'tag']" />
                          Best value
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </v-col>
        </v-row>


        <v-row class="text-black mt-6">
          <v-col cols="12" class="text-h6 font-weight-bold px-7">
            Rezerviraj testnu vožnju
          </v-col>
          <v-col align="center">
            <v-form @submit.prevent="sendEmail" ref="form" id="contact-form" class="mx-4">
              <v-text-field
                  v-model="formData.from_name"
                  name="from_name"
                  label="Ime"
                  required
                  density="compact"
                  variant="outlined"
              ></v-text-field>

              <v-text-field
                  v-model="formData.contact"
                  name="contact"
                  label="E-mail"
                  type="email"
                  density="compact"
                  variant="outlined"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="formData.time"
                  name="time"
                  label="Vrijeme"
                  type="time"
                  density="compact"
                  variant="outlined"
                  required
              ></v-text-field>

              <v-textarea
                  v-model="formData.message"
                  name="message"
                  label="Poruka"
                  density="compact"
                  variant="outlined"
                  required
              ></v-textarea>
              <v-btn type="submit" color="primary" rounded>Pošalji</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import BikeCard from "@/components/BikeCard.vue";
import * as emailjs from "emailjs-com";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'App',
  components: {FontAwesomeIcon, BikeCard},

  data: () => ({
    drawer: false,
    formData: {
      from_name: '',
      contact: '',
      message: '',
    },
    prices: [
      { duration: '12 mj', price: '14,99', save: '24%', description: '12 mjeseci pretplata', isRecommended: true, flexible: false },
      { duration: '9 mj', price: '16,99', save: '18%', description: '9 mjeseci pretplata', isRecommended: false, flexible: false },
      { duration: '6 mj', price: '18,99', save: '14%', description: '6 mjeseci pretplata', isRecommended: false, flexible: false },
      { duration: '3 mj', price: '19,99', save: '8%', description: '3 mjeseca pretplata', isRecommended: false, flexible: false },
      { duration: 'Flexible', price: '24,99', save: '', description: 'Moguće otkazati u svakome trenutku', isRecommended: false, flexible: true }
    ]
  }),

  methods: {
    sendEmail() {
      emailjs.sendForm(
          'service_mvb28m9',
          'template_hgy207c',
          '#contact-form',
          'IUXDtvfAnEjTsfdPC'
      ).then(
          () => {
            this.$refs.form.reset();
          }
      );
    }
  }
}
</script>

<style scoped>
body {
  font-family: 'Poppins', 'serif'
}
</style>
